import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'

import { Layout } from '../components/layout'
import { Post } from '../components/post'
import { absoluteUrl } from '../utils/url'

export default function CategoryTemplate({ data, pageContext: ctx, location }) {
  const { posts = [] } = data.allMarkdown || {}
  const { images = [] } = data.allImage || {}
  const { category } = ctx
  const description = `Všechny články z kategorie ${category} na jednom místě.`
  const imageMap = images.reduce((map, item) => {
    const image = item.node.fluid
    map[image.originalName] = image
    return map
  }, {})
  return (
    <Layout>
      <Helmet>
        <title>{category}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={absoluteUrl(location.pathname)} />
        <meta property="og:title" content={category} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Headline title={`Kategorie – ${category}`}>{category}</Headline>
      {posts.map(({ post }) => (
        <Post
          {...post.frontmatter}
          {...post.fields}
          key={post.id}
          image={imageMap[post.fields.image]}
          timeToRead={post.timeToRead}
        />
      ))}
    </Layout>
  )
}

/* Query */
export const query = graphql`
  query ($categorySlug: String!) {
    allMarkdown: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { categorySlug: { eq: $categorySlug } } }
    ) {
      totalCount
      posts: edges {
        post: node {
          id
          frontmatter {
            category
            date
            perex
            title
          }
          fields {
            slug
            categorySlug
            image
          }
          timeToRead
        }
      }
    }
    allImage: allImageSharp {
      totalCount
      images: edges {
        node {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
    }
  }
`

/* Styles */
const Headline = styled.h1`
  text-transform: uppercase;
  letter-spacing: 1px;
  && {
    margin-top: 0;
    padding-top: 0;
  }
`
